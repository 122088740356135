import React from 'react';
import { Link } from "react-router-dom";
import { Menu, Row, Col, Dropdown, Button, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getFamily, getJwtFromStorage } from '../../../../helpers/localstorage';
import { basePath, homePath } from '../../../../helpers/urlHelper';
import SystemInfo from '../SystemInfo';
import { isInRole } from '../../../../helpers/jwt';
import { ADMIN } from '../../../../constants/roles';
import FamilyDropDown from './FamilyDropdown';
import useLogout from '../../../../hooks/useLogout';
import { DarkModeSwitch } from '../../../DarkModeSwitch';
import { useSignalR } from '../../../../contexts/SignalRContext';

export default function DisplayBrowser() {
  const [handleLogout] = useLogout();
  const { DisplayStatusBadge } = useSignalR();

  const userProfileDropdown = (
    <Menu>
      <Menu.Item style={{ cursor: "unset" }}>
        <DarkModeSwitch />
      </Menu.Item>
      {isInRole(ADMIN) && <Menu.Item key="system-info" style={{ cursor: "unset" }}>
        <SystemInfo />
      </Menu.Item>}
      <Menu.Item key="1">
        <Link to={`${basePath()}/userprofile`}>User Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const userProfile = () => {
    if (getJwtFromStorage()) {
      return (
        <Col lg={{ span: 2 }} md={{ span: 0 }} sm={{ span: 0 }} xs={{ span: 0 }} className="header-user-account" align="center">
          <Dropdown overlay={userProfileDropdown}
            placement="bottom">
            <div className="ant-dropdown-link" style={{ cursor: "pointer" }} >
              <UserOutlined />
            </div>
          </Dropdown>
        </Col>);
    }
    else {
      return (
        <Col lg={{ span: 4 }} md={{ span: 0 }} sm={{ span: 0 }} xs={{ span: 0 }} className="header-user-account" align="center">
          <Space>
            <DarkModeSwitch />
            <Link to={`${basePath()}/login`} style={{ fontSize: 16 }}>
              <Button type="link">
                Log In / Sign Up
              </Button>
            </Link>
          </Space>
        </Col>
      );
    }
  };

  return (
    <Row>
      <Col
        xs={0}
        sm={0}
        md={{ span: 3 }}
        lg={{ span: 2 }}
        className="mainTitle" >
        <Link to={homePath()}>Nomnom</Link>
      </Col>
      <Col
        xs={{ span: 24 }}
        md={{ span: 17 }}
        lg={{ span: 18 }}
      >
        {getJwtFromStorage() &&
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="list">
              <Link to={`${basePath()}/list`}>
                <Space>
                  List
                  <DisplayStatusBadge />
                </Space>
              </Link>
            </Menu.Item>
            <Menu.Item key="ingredients">
              <Link to={`${basePath()}/ingredients/list`}>
                Ingredients
              </Link>
            </Menu.Item>
            <Menu.Item key="recipes">
              <Link to={`${basePath()}/recipes/list`}>
                Recipes
              </Link>
            </Menu.Item>
            <Menu.Item key="family">
              <Link to={`${basePath()}/family/general`}>
                Family
              </Link>
            </Menu.Item>
            {isInRole(ADMIN) &&
              <Menu.Item key="admin">
                <Link to={`${basePath()}/admin/reports`}>
                  Admin
                </Link>
              </Menu.Item>
            }
          </Menu>
        }
      </Col>
      <>
        {getJwtFromStorage() && getFamily() &&
        <Col lg={{ span: 2 }} md={{ span: 0 }} sm={{ span: 0 }} xs={{ span: 0 }}>
          <FamilyDropDown />
        </Col>
        }
        {userProfile()}

      </>
    </Row>
  );
};